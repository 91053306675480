<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.6616 15.7101C11.0559 16.3168 11.9441 16.3168 12.3384 15.7101L16.9958 8.54499C17.4282 7.87974 16.9508 7 16.1573 7H6.84269C6.04925 7 5.57183 7.87973 6.00424 8.54499L10.6616 15.7101Z"
      fill="#445E91"
    />
  </svg>
</template>

<script>
  export default {
    name: 'ArrowItem'
  }
</script>

<style scoped></style>
