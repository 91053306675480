<template>
  <iframe
    class="iframe"
    :src="this.videoUrl"
    title="YouTube video player"
    frameborder="0"
    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
  ></iframe>
</template>

<script>
  export default {
    name: 'YoutubeFrame',
    props: ['videoUrl']
  }
</script>

<style scoped></style>
