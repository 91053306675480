<template>
  <div v-if="isShow" ref="confirmModal" class="modal">
    <div class="modal-card">
      <div class="modal-card-header"></div>
      <div class="modal-card-body" v-html="options.message">
      </div>
      <div class="modal-card-actions">
        <a href="#" v-if="options.cancelButton" class="close-btn" @click.prevent="cancel">{{ options.cancelButton }}</a>
        <a href="#" v-if="options.confirmButton" class="confirm-btn" @click.prevent="confirm">{{ options.confirmButton }}</a>
      </div>
    </div>
  </div>
</template>

<script>
  /**
   * Modal returning a promise confirm
   *
   * <confirm-modal ref="confirmModal"/>
   * this.$confirmModal.open().then()
   */
  export default {
    name: 'ConfirmModal',
    data() {
      return {
        options: {
          message: '',
          confirmButton: 'Confirm',
          cancelButton: 'Close'
        },
        isShow: false,
        resolve: null,
        reject: null
      }
    },
    methods: {
      open(settings = {}) {
        this.options = {
          message: 'Are you sure you want to delete?',
          confirmButton: 'Confirm',
          cancelButton: 'Close'
        }

        for (const key in settings) {
          if (settings[key] !== undefined) {
            this.options[key] = settings[key]
          }
        }

        this.isShow = true
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      confirm() {
        this.resolve(true)
        this.isShow = false
      },
      cancel() {
        this.resolve(false)
        this.isShow = false
      }
    }
  }
</script>
<style lang="scss">
  .modal {
    z-index: 170;
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    background-color: rgba(10,10,10,.57);
    .modal-card {
      min-width: 320px;
      max-width: 400px;
      width: auto;
      &-header {
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        width: 100%;
        height: 80px;
        background-image: url("../assets/img/twoManOnBike.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
      &-body {
        background-color: #2c3e50;
        color: #ffffff;
        padding: 15px 20px;
        min-height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &-actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0 20px 15px 0;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
        background-color: #2c3e50;
        .close-btn {
          color: #aaa;
          font-weight: 500;
        }
        .confirm-btn {
          display: inline-block;
          padding: 4px 20px;
          text-decoration: none;
          font-weight: 500;
          background-color: #13ba14;
          color: #ffffff;
          margin-left: 20px;
          border-radius: 2px;
        }
      }
    }
  }
</style>
