import * as Chart from './DrawChart'
import debounce from 'lodash.debounce'

export default {
  data() {
    return {
      cursorLineX: null,
      cursorMouseSec: null,
      debouncedResize: () => {}
    }
  },
  mounted() {
    this.$nextTick(() => this.renderCanvasChart())

    this.debouncedResize = debounce(() => {
      this.renderCanvasChart()
    }, 100)

    window.addEventListener('resize', this.debouncedResize)
  },
  beforeDestroy() {
    const { canvas } = this.$refs
    window.removeEventListener('resize', this.debouncedResize)
    if (canvas) {
      canvas.removeEventListener('mousemove', this.onMouseMove)
      canvas.removeEventListener('mouseleave', this.onMouseLeave)
    }
  },
  watch: {
    segments() {
      this.addMouseEvent()
      this.renderCanvasChart()
    },
    criticalPower() {
      this.renderCanvasChart()
    },
    wPrime() {
      this.renderCanvasChart()
    },
    showWBal() {
      this.renderCanvasChart()
    },
    showHRDiagram() {
      this.renderCanvasChart()
    }
  },
  methods: {
    onMouseMove({ clientX }) {
      const { canvas } = this.$refs
      const { left } = canvas.getBoundingClientRect()
      this.cursorLineX = Math.abs(clientX - left)
      this.renderCanvasChart()
    },

    onMouseLeave() {
      this.cursorLineX = null
      this.renderCanvasChart()
      this.cursorMouseSec = null
    },

    addMouseEvent() {
      const { $refs, showCursorLine } = this
      const { canvas } = $refs

      if (!canvas || !showCursorLine) {
        return
      }

      canvas.addEventListener('mousemove', this.onMouseMove)
      canvas.addEventListener('mouseleave', this.onMouseLeave)
    },

    renderCanvasChart() {
      const {
        ftp,
        thresholdHR,
        segments,
        watts,
        bpm,
        chartType,
        criticalPower,
        wPrime,
        maxValueY,
        showWattsDiagram,
        showHRDiagram,
        showWBal,
        showCursorLine,
        chartAreaBackgroundColor,
        $refs,
        cursorLineX,
        isWorkoutHRUnits
      } = this

      const { canvas } = $refs

      if (!canvas) {
        return
      }

      canvas.style = 'width: 100%; height: 100%;'

      const { width, height } = canvas.getBoundingClientRect()

      // eslint-disable-next-line no-new
      const chart = new Chart({
        segments,
        watts,
        bpm,
        chartType,
        criticalPower,
        wPrime,
        showWattsDiagram,
        showHRDiagram,
        showWBal,
        canvas,
        width,
        height,
        ftp,
        thresholdHR,
        maxValueY: maxValueY || ftp * 2,
        cursorLineX: showCursorLine ? cursorLineX : null,
        chartAreaBackgroundColor,
        isHRSegments: isWorkoutHRUnits
      })
      const { cursorX } = chart
      this.cursorMouseSec = Math.round(cursorX) // current mouse second
    }
  }
}
