<template>
  <div class="root">
    <YoutubeFrame
      v-if="showYoutube && !youTubeList"
      :videoUrl="videoUrlForShow"
      style="position: relative; height: 70%"
    >
    </YoutubeFrame>
    <CanvasChart
      v-if="chart && !showYoutube && !showChartInBottomBar"
      :segments="history.segments"
      :bpm="history.bpm"
      :rpm="history.rpm"
      :ftp="history.ftp"
      :thresholdHR="user.thresholdHR"
      :watts="history.watts"
      :showWattsDiagram="true"
    />
    <div class="pause-label" v-if="isPause">Pause</div>
    <VideosList v-if="youTubeList" @videoUrl="getVideoUrlForShow" />
    <section class="bottom-section">
      <RouteMapBlock ref="routeMapBlock" />
      <BottomBar class="bottom-bar" />
    </section>
  </div>
</template>

<script>
  import { SegmentSoundEffectType } from '@trainerday/ergdb-types'
  import configuration from './configuration'
  import BottomBar from '@/components/BottomBar'
  import YoutubeFrame from '@/components/YoutubeFrame'
  import VideosList from '@/components/VideosList'
  import RouteMapBlock from '@/components/RouteMapBlock'
  import { mapActions, mapState } from 'vuex'
  import CanvasChart from '@/components/CanvasChart'
  const { websocketsUrl } = configuration
  const { io } = require('socket.io-client')

  const delay = (milliseconds) => new Promise((resolve) => setTimeout(resolve, milliseconds))

  export default {
    name: 'RealTimeClient',
    components: {
      CanvasChart,
      BottomBar,
      YoutubeFrame,
      VideosList,
      RouteMapBlock
    },
    async mounted() {
      const { userId: userIdFromQuery } = this.$route.params
      if (this.user.token) {
        await this.$store.dispatch('checkUserId', { userIdFromQuery })
        await Promise.all([this.$store.dispatch('getVideoList', { userIdFromQuery }), this.$store.dispatch('getRoute')])
      } else {
        await this.$store.dispatch('setHasLoaded')
      }
    },
    data() {
      return {
        activity: [],
        userId: null,
        second: 0,
        target: 0,
        interval: '00:00',
        total: '00:00',
        videoUrlForShow: '',
        soundEffects: {
          // used in older app builds
          beep3: new Audio('/beep_for_three.mp3'),
          beepUp: new Audio('/beep_up_v2.mp3'),
          beepDown: new Audio('/beep_down_v2.mp3'),

          // new app builds
          beepLong: new Audio('/beep_long.mp3'),
          beepShort: new Audio('/beep_short.mp3')
        }
      }
    },
    watch: {
      'current.soundEffect'(soundEffect) {
        try {
          this.playSound(soundEffect)
        } catch (e) {}
      },

      statusActivity(status) {
        if (status === 'ready') {
          this.$store.commit('clearHistory')
          this.$store.commit('clearCurrent')
        }
      },

      $screenHeight() {
        this.onPageResize()
      }
    },
    computed: {
      ...mapState(['user', 'history', 'current', 'userRoute']),
      isLogged() {
        return this.user.isLogged
      },
      isPause() {
        return (this.history.watts.length && !this.current.currentSecond) || this.statusActivity === 'pause'
      },
      chart() {
        return this.user.chart
      },
      showYoutube() {
        return this.user.youTubeFrame
      },
      showChartInBottomBar() {
        return this.user.chartInBottomBar
      },
      youTubeList() {
        return this.user.youTubeList
      },
      statusActivity() {
        return this.current.statusActivity
      }
    },
    created() {
      window.addEventListener('beforeunload', this.beforeWindowUnload)
      this.onPageResize()
      this.init()
      setInterval(async () => {
        try {
          const screenLock = await navigator.wakeLock.request('screen')
          await screenLock.release()
        } catch (e) {}
      }, 5000)
    },
    beforeDestroy() {
      window.removeEventListener('beforeunload', this.beforeWindowUnload)
    },
    methods: {
      ...mapActions(['checkUserId', 'showYoutubeFrame', 'showYoutubeVideo', 'setHasLoaded']),

      onPageResize() {
        const { chartInBottomBar, youTubeFrame } = this.$store.state.user
        if (this.$screenHeight <= 475 && !chartInBottomBar) {
          this.$store.state.user.chartInBottomBar = true
          this.$store.state.user.youTubeFrame = false
          this.$store.state.user.youTubeList = false
        }

        if (this.$screenHeight > 475 && chartInBottomBar && !youTubeFrame) {
          this.$store.state.user.chartInBottomBar = false
        }
      },

      async playSound(soundEffect) {
        if (soundEffect === SegmentSoundEffectType.SINGLE_BEEP) {
          this.soundEffects.beepLong.volume = 1
          await this.soundEffects.beepLong.play()
        }

        if (soundEffect === SegmentSoundEffectType.FOUR_BEEPS_LOW_VOLUME) {
          await this.playFourBeeps(0.32)
        }

        if (soundEffect === SegmentSoundEffectType.FOUR_BEEPS_MID_VOLUME) {
          await this.playFourBeeps(0.56)
        }

        if (soundEffect === SegmentSoundEffectType.FOUR_BEEPS_HIGH_VOLUME) {
          await this.playFourBeeps(1)
        }

        if (soundEffect === SegmentSoundEffectType.FOUR_BEEPS_PROGRESSIVE_VOLUME) {
          await this.playFourBeeps(0.2, true)
        }

        if (soundEffect === SegmentSoundEffectType.UP) {
          await this.soundEffects.beepUp.play()
        }

        if (soundEffect === SegmentSoundEffectType.DOWN) {
          await this.soundEffects.beepDown.play()
        }

        if (soundEffect === SegmentSoundEffectType.BEEPS_DOWN) {
          await this.soundEffects.beep3.play()
          await delay(800)
          await this.soundEffects.beep3.play()
          await delay(800)
          await this.soundEffects.beep3.play()
          await delay(800)
          await this.soundEffects.beepDown.play()
        }

        if (soundEffect === SegmentSoundEffectType.BEEPS_UP) {
          await this.soundEffects.beep3.play()
          await delay(800)
          await this.soundEffects.beep3.play()
          await delay(800)
          await this.soundEffects.beep3.play()
          await delay(800)
          await this.soundEffects.beepUp.play()
        }
      },

      async playFourBeeps(volume = 1, soundRise = false) {
        this.soundEffects.beepShort.volume = volume
        this.soundEffects.beepLong.volume = volume

        await this.soundEffects.beepShort.play()
        await delay(800)
        if (soundRise) {
          this.soundEffects.beepShort.volume += 0.3
        }
        await this.soundEffects.beepShort.play()
        await delay(800)
        if (soundRise) {
          this.soundEffects.beepShort.volume += 0.3
        }
        await this.soundEffects.beepShort.play()
        await delay(800)
        if (soundRise) {
          this.soundEffects.beepLong.volume = 1
        }
        await this.soundEffects.beepLong.play()
      },

      beforeWindowUnload(e) {
        if (this.userRoute.isLoaded && (this.statusActivity === 'playing' || this.statusActivity === 'pause')) {
          const confirmed = window.confirm('Do you really want to leave?')
          if (!confirmed) {
            e.preventDefault()
            e.returnValue = ''
          }
        }
      },

      getVideoUrlForShow(videoUrl) {
        this.$store.dispatch('showYoutubeVideo')
        let id
        if (videoUrl.indexOf('youtu.be/') > 0) {
          id = videoUrl.split('/')[3]
        } else {
          id = videoUrl.split('?v=')[1]
          if (id.indexOf('&') > 0) {
            id = id.substring(0, id.indexOf('&'))
          }
        }
        this.videoUrlForShow = 'https://www.youtube.com/embed/' + id
      },

      init() {
        const { userId } = this.$route.params
        this.userId = userId

        const socket = io(websocketsUrl)

        socket.on('connect', () => {
          socket.emit('login', { userId, isClient: true })
          socket.emit('getHistory')
        })

        socket.on('data', (data) => {
          this.$store.commit('setCurrent', data)
          this.$store.commit('updateHistory', {
            bpm: data.bpm,
            rpm: data.rpm,
            ftp: data.ftp,
            watts: data.watts,
            segments: data.workout.segments
          })
          const { currentSecond } = this.$store.state.current
          this.second = currentSecond
        })

        socket.on('sendHistory', (data) => {
          this.$store.commit('setHistory', data)
        })

        socket.on('statusChanged', (data) => {
          this.$store.state.current.statusActivity = data.statusActivity || data
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
.root {
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  background: #000000;
  .pause-label {
    font-size: 36px;
    color: #fff;
    position: absolute;
    left: calc(50% - 55px);
    top: 30%;
    animation: 0.8s linear infinite pauseLabel;
    @-webkit-keyframes pauseLabel {
      0% {
        opacity: 1;
      }
      33% {
        opacity: 0.2;
      }
      100% {
        opacity: 1;
      }
    }
  }
}
.bottom-section {
  height: 30%;
  position: relative;
}
@media (max-height: 700px) {
  .bottom-section {
    height: 40%;
  }
}
@media (max-height: 520px) {
  .bottom-section {
    height: 45%;
  }
}
@media (max-height: 475px) {
  .bottom-section {
    height: 100%;
  }
}
</style>
