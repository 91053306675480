let installed = false

const ScreenSizePlugin = {}

function updateOnlineStatus() {
  if (ScreenSizePlugin && ScreenSizePlugin.instance) {
    ScreenSizePlugin.instance.height = window.innerHeight
  }
}

ScreenSizePlugin.install = function(Vue) {
  if (!installed) {
    ScreenSizePlugin.instance = new Vue({
      data: function() {
        return {
          height: window.innerHeight
        }
      }
    })

    Object.defineProperty(Vue.prototype, '$screenHeight', {
      get: function get() {
        return ScreenSizePlugin.instance.height
      }
    })

    window.addEventListener('resize', updateOnlineStatus)
  }
  installed = true
}

if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(ScreenSizePlugin)
}

export default ScreenSizePlugin
