export default [
  {
    bpm: 29,
    rpm: 28,
    speed: 6,
    watts: 41,
    currentSecond: 0,
    currentSegmentIndex: 0,
    currentSegmentSecond: 4,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1496, interval: 296, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 42,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 26,
    rpm: 30,
    speed: 10,
    watts: 44,
    currentSecond: 6,
    currentSegmentIndex: 0,
    currentSegmentSecond: 5,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1495, interval: 295, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 42,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 29,
    rpm: 25,
    speed: 6,
    watts: 40,
    currentSecond: 7,
    currentSegmentIndex: 0,
    currentSegmentSecond: 6,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1494, interval: 294, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 43,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 28,
    rpm: 30,
    speed: 7,
    watts: 43,
    currentSecond: 8,
    currentSegmentIndex: 0,
    currentSegmentSecond: 7,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1493, interval: 293, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 43,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 29,
    rpm: 30,
    speed: 8,
    watts: 45,
    currentSecond: 9,
    currentSegmentIndex: 0,
    currentSegmentSecond: 8,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1492, interval: 292, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 43,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 29,
    rpm: 26,
    speed: 7,
    watts: 45,
    currentSecond: 10,
    currentSegmentIndex: 0,
    currentSegmentSecond: 9,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1491, interval: 291, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 44,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 26,
    rpm: 30,
    speed: 10,
    watts: 41,
    currentSecond: 11,
    currentSegmentIndex: 0,
    currentSegmentSecond: 10,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1490, interval: 290, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 44,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 26,
    rpm: 31,
    speed: 9,
    watts: 46,
    currentSecond: 12,
    currentSegmentIndex: 0,
    currentSegmentSecond: 11,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1489, interval: 289, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 44,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 30,
    rpm: 26,
    speed: 9,
    watts: 45,
    currentSecond: 13,
    currentSegmentIndex: 0,
    currentSegmentSecond: 12,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1488, interval: 288, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 44,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 28,
    rpm: 30,
    speed: 12,
    watts: 46,
    currentSecond: 14,
    currentSegmentIndex: 0,
    currentSegmentSecond: 13,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1487, interval: 287, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 45,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 27,
    rpm: 28,
    speed: 7,
    watts: 44,
    currentSecond: 15,
    currentSegmentIndex: 0,
    currentSegmentSecond: 14,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1486, interval: 286, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 45,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 30,
    rpm: 28,
    speed: 7,
    watts: 45,
    currentSecond: 16,
    currentSegmentIndex: 0,
    currentSegmentSecond: 15,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1485, interval: 285, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 45,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 28,
    rpm: 32,
    speed: 10,
    watts: 45,
    currentSecond: 17,
    currentSegmentIndex: 0,
    currentSegmentSecond: 16,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1484, interval: 284, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 45,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 32,
    rpm: 30,
    speed: 11,
    watts: 47,
    currentSecond: 18,
    currentSegmentIndex: 0,
    currentSegmentSecond: 17,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1483, interval: 283, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 46,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 31,
    rpm: 32,
    speed: 7,
    watts: 46,
    currentSecond: 19,
    currentSegmentIndex: 0,
    currentSegmentSecond: 18,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1482, interval: 282, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 46,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 28,
    rpm: 29,
    speed: 11,
    watts: 47,
    currentSecond: 20,
    currentSegmentIndex: 0,
    currentSegmentSecond: 19,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1481, interval: 281, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 46,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 30,
    rpm: 29,
    speed: 7,
    watts: 46,
    currentSecond: 21,
    currentSegmentIndex: 0,
    currentSegmentSecond: 20,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1480, interval: 280, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 47,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 30,
    rpm: 30,
    speed: 11,
    watts: 47,
    currentSecond: 22,
    currentSegmentIndex: 0,
    currentSegmentSecond: 21,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1479, interval: 279, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 47,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 32,
    rpm: 28,
    speed: 11,
    watts: 46,
    currentSecond: 23,
    currentSegmentIndex: 0,
    currentSegmentSecond: 22,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1478, interval: 278, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 47,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 30,
    rpm: 29,
    speed: 10,
    watts: 47,
    currentSecond: 24,
    currentSegmentIndex: 0,
    currentSegmentSecond: 23,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1477, interval: 277, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 47,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 31,
    rpm: 30,
    speed: 7,
    watts: 49,
    currentSecond: 25,
    currentSegmentIndex: 0,
    currentSegmentSecond: 24,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1476, interval: 276, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 48,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 29,
    rpm: 32,
    speed: 7,
    watts: 47,
    currentSecond: 26,
    currentSegmentIndex: 0,
    currentSegmentSecond: 25,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1475, interval: 275, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 48,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 34,
    rpm: 33,
    speed: 13,
    watts: 50,
    currentSecond: 27,
    currentSegmentIndex: 0,
    currentSegmentSecond: 26,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1474, interval: 274, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 48,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 30,
    rpm: 30,
    speed: 12,
    watts: 45,
    currentSecond: 28,
    currentSegmentIndex: 0,
    currentSegmentSecond: 27,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1473, interval: 273, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 49,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 31,
    rpm: 34,
    speed: 10,
    watts: 48,
    currentSecond: 29,
    currentSegmentIndex: 0,
    currentSegmentSecond: 28,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1472, interval: 272, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 49,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 34,
    rpm: 35,
    speed: 10,
    watts: 47,
    currentSecond: 30,
    currentSegmentIndex: 0,
    currentSegmentSecond: 29,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1471, interval: 271, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 49,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 35,
    rpm: 30,
    speed: 8,
    watts: 48,
    currentSecond: 31,
    currentSegmentIndex: 0,
    currentSegmentSecond: 30,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1470, interval: 270, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 49,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 30,
    rpm: 34,
    speed: 13,
    watts: 49,
    currentSecond: 32,
    currentSegmentIndex: 0,
    currentSegmentSecond: 31,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1469, interval: 269, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 50,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 35,
    rpm: 33,
    speed: 12,
    watts: 49,
    currentSecond: 33,
    currentSegmentIndex: 0,
    currentSegmentSecond: 32,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1468, interval: 268, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 50,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 34,
    rpm: 30,
    speed: 8,
    watts: 50,
    currentSecond: 34,
    currentSegmentIndex: 0,
    currentSegmentSecond: 33,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1467, interval: 267, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 50,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 32,
    rpm: 32,
    speed: 7,
    watts: 51,
    currentSecond: 35,
    currentSegmentIndex: 0,
    currentSegmentSecond: 34,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1466, interval: 266, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 51,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 34,
    rpm: 35,
    speed: 12,
    watts: 53,
    currentSecond: 36,
    currentSegmentIndex: 0,
    currentSegmentSecond: 35,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1465, interval: 265, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 51,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 32,
    rpm: 33,
    speed: 11,
    watts: 51,
    currentSecond: 37,
    currentSegmentIndex: 0,
    currentSegmentSecond: 36,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1464, interval: 264, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 51,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 31,
    rpm: 31,
    speed: 12,
    watts: 51,
    currentSecond: 38,
    currentSegmentIndex: 0,
    currentSegmentSecond: 37,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1463, interval: 263, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 51,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 36,
    rpm: 34,
    speed: 11,
    watts: 49,
    currentSecond: 39,
    currentSegmentIndex: 0,
    currentSegmentSecond: 38,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1462, interval: 262, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 52,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 34,
    rpm: 36,
    speed: 10,
    watts: 49,
    currentSecond: 40,
    currentSegmentIndex: 0,
    currentSegmentSecond: 39,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1461, interval: 261, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 52,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 35,
    rpm: 35,
    speed: 10,
    watts: 51,
    currentSecond: 41,
    currentSegmentIndex: 0,
    currentSegmentSecond: 40,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1460, interval: 260, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 52,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 33,
    rpm: 34,
    speed: 8,
    watts: 50,
    currentSecond: 42,
    currentSegmentIndex: 0,
    currentSegmentSecond: 41,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1459, interval: 259, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 52,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 34,
    rpm: 33,
    speed: 11,
    watts: 54,
    currentSecond: 43,
    currentSegmentIndex: 0,
    currentSegmentSecond: 42,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1458, interval: 258, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 53,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 35,
    rpm: 37,
    speed: 14,
    watts: 55,
    currentSecond: 44,
    currentSegmentIndex: 0,
    currentSegmentSecond: 43,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1457, interval: 257, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 53,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 37,
    rpm: 33,
    speed: 13,
    watts: 52,
    currentSecond: 45,
    currentSegmentIndex: 0,
    currentSegmentSecond: 44,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1456, interval: 256, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 53,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 37,
    rpm: 34,
    speed: 11,
    watts: 51,
    currentSecond: 46,
    currentSegmentIndex: 0,
    currentSegmentSecond: 45,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1455, interval: 255, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 54,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 33,
    rpm: 38,
    speed: 11,
    watts: 52,
    currentSecond: 47,
    currentSegmentIndex: 0,
    currentSegmentSecond: 46,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1454, interval: 254, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 54,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 36,
    rpm: 36,
    speed: 10,
    watts: 54,
    currentSecond: 48,
    currentSegmentIndex: 0,
    currentSegmentSecond: 47,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1453, interval: 253, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 54,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 38,
    rpm: 36,
    speed: 12,
    watts: 55,
    currentSecond: 49,
    currentSegmentIndex: 0,
    currentSegmentSecond: 48,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1452, interval: 252, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 54,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 37,
    rpm: 35,
    speed: 11,
    watts: 53,
    currentSecond: 50,
    currentSegmentIndex: 0,
    currentSegmentSecond: 49,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1451, interval: 251, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 55,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 33,
    rpm: 38,
    speed: 9,
    watts: 57,
    currentSecond: 51,
    currentSegmentIndex: 0,
    currentSegmentSecond: 50,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1450, interval: 250, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 55,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 35,
    rpm: 36,
    speed: 14,
    watts: 54,
    currentSecond: 52,
    currentSegmentIndex: 0,
    currentSegmentSecond: 51,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1449, interval: 249, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 55,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 34,
    rpm: 35,
    speed: 12,
    watts: 54,
    currentSecond: 53,
    currentSegmentIndex: 0,
    currentSegmentSecond: 52,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1448, interval: 248, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 56,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 37,
    rpm: 34,
    speed: 13,
    watts: 53,
    currentSecond: 54,
    currentSegmentIndex: 0,
    currentSegmentSecond: 53,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1447, interval: 247, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 56,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 37,
    rpm: 36,
    speed: 9,
    watts: 58,
    currentSecond: 55,
    currentSegmentIndex: 0,
    currentSegmentSecond: 54,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1446, interval: 246, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 56,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 35,
    rpm: 35,
    speed: 8,
    watts: 58,
    currentSecond: 56,
    currentSegmentIndex: 0,
    currentSegmentSecond: 55,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1445, interval: 245, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 56,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 37,
    rpm: 38,
    speed: 12,
    watts: 56,
    currentSecond: 57,
    currentSegmentIndex: 0,
    currentSegmentSecond: 56,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1444, interval: 244, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 57,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 36,
    rpm: 39,
    speed: 10,
    watts: 57,
    currentSecond: 58,
    currentSegmentIndex: 0,
    currentSegmentSecond: 57,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1443, interval: 243, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 57,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 38,
    rpm: 35,
    speed: 10,
    watts: 58,
    currentSecond: 59,
    currentSegmentIndex: 0,
    currentSegmentSecond: 58,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1442, interval: 242, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 57,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 39,
    rpm: 38,
    speed: 13,
    watts: 57,
    currentSecond: 60,
    currentSegmentIndex: 0,
    currentSegmentSecond: 59,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1441, interval: 241, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 58,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 36,
    rpm: 35,
    speed: 13,
    watts: 58,
    currentSecond: 61,
    currentSegmentIndex: 0,
    currentSegmentSecond: 60,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1440, interval: 240, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 58,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 37,
    rpm: 38,
    speed: 9,
    watts: 58,
    currentSecond: 62,
    currentSegmentIndex: 0,
    currentSegmentSecond: 61,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1439, interval: 239, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 58,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 37,
    rpm: 40,
    speed: 14,
    watts: 60,
    currentSecond: 63,
    currentSegmentIndex: 0,
    currentSegmentSecond: 62,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1438, interval: 238, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 58,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 40,
    rpm: 36,
    speed: 9,
    watts: 60,
    currentSecond: 64,
    currentSegmentIndex: 0,
    currentSegmentSecond: 63,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1437, interval: 237, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 59,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 38,
    rpm: 40,
    speed: 15,
    watts: 61,
    currentSecond: 65,
    currentSegmentIndex: 0,
    currentSegmentSecond: 64,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1436, interval: 236, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 59,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 41,
    rpm: 41,
    speed: 13,
    watts: 59,
    currentSecond: 66,
    currentSegmentIndex: 0,
    currentSegmentSecond: 65,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1435, interval: 235, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 59,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 39,
    rpm: 41,
    speed: 13,
    watts: 61,
    currentSecond: 67,
    currentSegmentIndex: 0,
    currentSegmentSecond: 66,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1434, interval: 234, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 59,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 40,
    rpm: 37,
    speed: 12,
    watts: 58,
    currentSecond: 68,
    currentSegmentIndex: 0,
    currentSegmentSecond: 67,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1433, interval: 233, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 60,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 38,
    rpm: 41,
    speed: 13,
    watts: 60,
    currentSecond: 69,
    currentSegmentIndex: 0,
    currentSegmentSecond: 68,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1432, interval: 232, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 60,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 38,
    rpm: 39,
    speed: 11,
    watts: 57,
    currentSecond: 70,
    currentSegmentIndex: 0,
    currentSegmentSecond: 69,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1431, interval: 231, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 60,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 41,
    rpm: 41,
    speed: 12,
    watts: 57,
    currentSecond: 71,
    currentSegmentIndex: 0,
    currentSegmentSecond: 70,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1430, interval: 230, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 61,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 41,
    rpm: 39,
    speed: 14,
    watts: 61,
    currentSecond: 72,
    currentSegmentIndex: 0,
    currentSegmentSecond: 71,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1429, interval: 229, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 61,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 39,
    rpm: 39,
    speed: 15,
    watts: 62,
    currentSecond: 73,
    currentSegmentIndex: 0,
    currentSegmentSecond: 72,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1428, interval: 228, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 61,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 40,
    rpm: 41,
    speed: 15,
    watts: 60,
    currentSecond: 74,
    currentSegmentIndex: 0,
    currentSegmentSecond: 73,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1427, interval: 227, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 61,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 40,
    rpm: 42,
    speed: 11,
    watts: 60,
    currentSecond: 75,
    currentSegmentIndex: 0,
    currentSegmentSecond: 74,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1426, interval: 226, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 62,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 40,
    rpm: 40,
    speed: 10,
    watts: 60,
    currentSecond: 76,
    currentSegmentIndex: 0,
    currentSegmentSecond: 75,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1425, interval: 225, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 62,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 43,
    rpm: 39,
    speed: 10,
    watts: 60,
    currentSecond: 77,
    currentSegmentIndex: 0,
    currentSegmentSecond: 76,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1424, interval: 224, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 62,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 40,
    rpm: 44,
    speed: 14,
    watts: 62,
    currentSecond: 78,
    currentSegmentIndex: 0,
    currentSegmentSecond: 77,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1423, interval: 223, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 63,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 43,
    rpm: 43,
    speed: 13,
    watts: 62,
    currentSecond: 79,
    currentSegmentIndex: 0,
    currentSegmentSecond: 78,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1422, interval: 222, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 63,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 41,
    rpm: 40,
    speed: 13,
    watts: 64,
    currentSecond: 80,
    currentSegmentIndex: 0,
    currentSegmentSecond: 79,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1421, interval: 221, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 63,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 44,
    rpm: 43,
    speed: 15,
    watts: 63,
    currentSecond: 81,
    currentSegmentIndex: 0,
    currentSegmentSecond: 80,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1420, interval: 220, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 63,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 44,
    rpm: 43,
    speed: 11,
    watts: 61,
    currentSecond: 82,
    currentSegmentIndex: 0,
    currentSegmentSecond: 81,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1419, interval: 219, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 64,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 39,
    rpm: 41,
    speed: 11,
    watts: 62,
    currentSecond: 83,
    currentSegmentIndex: 0,
    currentSegmentSecond: 82,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1418, interval: 218, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 64,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 42,
    rpm: 44,
    speed: 11,
    watts: 64,
    currentSecond: 84,
    currentSegmentIndex: 0,
    currentSegmentSecond: 83,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1417, interval: 217, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 64,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 45,
    rpm: 43,
    speed: 16,
    watts: 61,
    currentSecond: 85,
    currentSegmentIndex: 0,
    currentSegmentSecond: 84,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1416, interval: 216, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 65,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 40,
    rpm: 42,
    speed: 16,
    watts: 64,
    currentSecond: 86,
    currentSegmentIndex: 0,
    currentSegmentSecond: 85,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1415, interval: 215, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 65,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 43,
    rpm: 40,
    speed: 14,
    watts: 65,
    currentSecond: 87,
    currentSegmentIndex: 0,
    currentSegmentSecond: 86,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1414, interval: 214, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 65,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 45,
    rpm: 42,
    speed: 11,
    watts: 66,
    currentSecond: 88,
    currentSegmentIndex: 0,
    currentSegmentSecond: 87,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1413, interval: 213, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 65,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 41,
    rpm: 42,
    speed: 14,
    watts: 65,
    currentSecond: 89,
    currentSegmentIndex: 0,
    currentSegmentSecond: 88,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1412, interval: 212, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 66,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 41,
    rpm: 45,
    speed: 14,
    watts: 68,
    currentSecond: 90,
    currentSegmentIndex: 0,
    currentSegmentSecond: 89,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1411, interval: 211, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 66,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 46,
    rpm: 42,
    speed: 14,
    watts: 66,
    currentSecond: 91,
    currentSegmentIndex: 0,
    currentSegmentSecond: 90,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1410, interval: 210, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 66,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 43,
    rpm: 46,
    speed: 15,
    watts: 64,
    currentSecond: 92,
    currentSegmentIndex: 0,
    currentSegmentSecond: 91,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1409, interval: 209, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 66,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 43,
    rpm: 45,
    speed: 14,
    watts: 68,
    currentSecond: 93,
    currentSegmentIndex: 0,
    currentSegmentSecond: 92,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1408, interval: 208, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 67,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 42,
    rpm: 45,
    speed: 14,
    watts: 68,
    currentSecond: 94,
    currentSegmentIndex: 0,
    currentSegmentSecond: 93,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1407, interval: 207, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 67,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 45,
    rpm: 46,
    speed: 15,
    watts: 69,
    currentSecond: 95,
    currentSegmentIndex: 0,
    currentSegmentSecond: 94,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1406, interval: 206, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 67,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 44,
    rpm: 45,
    speed: 12,
    watts: 64,
    currentSecond: 96,
    currentSegmentIndex: 0,
    currentSegmentSecond: 95,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1405, interval: 205, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 68,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 46,
    rpm: 44,
    speed: 14,
    watts: 67,
    currentSecond: 97,
    currentSegmentIndex: 0,
    currentSegmentSecond: 96,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1404, interval: 204, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 68,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 42,
    rpm: 43,
    speed: 12,
    watts: 68,
    currentSecond: 98,
    currentSegmentIndex: 0,
    currentSegmentSecond: 97,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1403, interval: 203, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 68,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 42,
    rpm: 43,
    speed: 14,
    watts: 67,
    currentSecond: 99,
    currentSegmentIndex: 0,
    currentSegmentSecond: 98,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1402, interval: 202, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 68,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 43,
    rpm: 43,
    speed: 12,
    watts: 67,
    currentSecond: 100,
    currentSegmentIndex: 0,
    currentSegmentSecond: 99,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1401, interval: 201, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 69,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 48,
    rpm: 46,
    speed: 17,
    watts: 68,
    currentSecond: 101,
    currentSegmentIndex: 0,
    currentSegmentSecond: 100,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1400, interval: 200, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 69,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 45,
    rpm: 43,
    speed: 15,
    watts: 68,
    currentSecond: 102,
    currentSegmentIndex: 0,
    currentSegmentSecond: 101,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1399, interval: 199, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 69,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 43,
    rpm: 48,
    speed: 15,
    watts: 69,
    currentSecond: 103,
    currentSegmentIndex: 0,
    currentSegmentSecond: 102,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1398, interval: 198, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 70,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 46,
    rpm: 47,
    speed: 15,
    watts: 69,
    currentSecond: 104,
    currentSegmentIndex: 0,
    currentSegmentSecond: 103,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1397, interval: 197, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 70,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 48,
    rpm: 45,
    speed: 12,
    watts: 70,
    currentSecond: 105,
    currentSegmentIndex: 0,
    currentSegmentSecond: 104,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1396, interval: 196, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 70,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 45,
    rpm: 44,
    speed: 11,
    watts: 69,
    currentSecond: 106,
    currentSegmentIndex: 0,
    currentSegmentSecond: 105,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1395, interval: 195, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 70,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 49,
    rpm: 46,
    speed: 16,
    watts: 68,
    currentSecond: 107,
    currentSegmentIndex: 0,
    currentSegmentSecond: 106,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1394, interval: 194, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 71,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 48,
    rpm: 46,
    speed: 15,
    watts: 71,
    currentSecond: 108,
    currentSegmentIndex: 0,
    currentSegmentSecond: 107,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1393, interval: 193, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 71,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 45,
    rpm: 45,
    speed: 12,
    watts: 72,
    currentSecond: 109,
    currentSegmentIndex: 0,
    currentSegmentSecond: 108,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1392, interval: 192, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 71,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 48,
    rpm: 49,
    speed: 17,
    watts: 72,
    currentSecond: 110,
    currentSegmentIndex: 0,
    currentSegmentSecond: 109,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'playing',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1391, interval: 191, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 72,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 49,
    rpm: 50,
    speed: 16,
    watts: 69,
    currentSecond: 111,
    currentSegmentIndex: 0,
    currentSegmentSecond: 110,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'finished',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1390, interval: 190, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 72,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  },
  {
    bpm: 49,
    rpm: 50,
    speed: 16,
    watts: 69,
    currentSecond: 0,
    currentSegmentIndex: 0,
    currentSegmentSecond: 110,
    workout: {
      segments: [
        [5, 41, 125, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.5, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [1.25, 170, 170, null, null, null, null, null],
        [0.25, 41, 41, null, null, null, null, null],
        [2, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 170, 170, null, null, null, null, null],
        [0.33, 41, 41, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [0.33, 170, 170, null, null, null, null, null],
        [1.67, 41, 41, null, null, null, null, null],
        [5, 41, 125, null, null, null, null, null]
      ],
      durationMinutes: 25,
      isRampTest: false,
      title: 'MICRO INTERVALS I'
    },
    statusActivity: 'ready',
    userOffsetTarget: { power: 100, resistance: 15, slope: 0 },
    mode: 'ERG',
    time: { total: 1390, interval: 190, isTotalUp: true, isIntervalUp: false },
    ftp: 150,
    segmentText: 150,
    rpmTarget: [],
    sportType: 'bike',
    targetWatts: 72,
    nextIntervalText: '',
    deviceStatus: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    devices: { connected: 0, powerMeter: false, heartRateMeter: false, cadenceMeter: false },
    statusWorkout: 'playing'
  }
].map((item, i) => {
  item.distance = i * 10
  item.startDateLocal = '2023-01-30T12:18:03.732Z'
  return item
})
