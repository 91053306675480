<template>
  <div class="container bottom-bar" :class="{'hidden-bottom-row': !isShowBottomRow}" id="bottom-bar" >
    <div v-if="chartInBottomBar" class="chart">
      <CanvasChart
        :segments="history.segments"
        :bpm="history.bpm"
        :rpm="history.rpm"
        :ftp="history.ftp"
        :watts="history.watts"
        :showWattsDiagram="true"
        :showHRDiagram="true"
        class="item"
      />
    </div>

    <div class="target-section">
      <div class="block segment-comment-block">
        <div class="comment" id="segment-comment">
          {{ segmentComment.text || '' }}
        </div>
        <span
            v-if="current.nextIntervalText !== undefined"
            class="next-int"
            :class="{
              'blinking': current.time.interval < 5 && current.statusActivity === 'playing',
              'reduced': segmentComment.text
            }"
        >
          <template v-if="current.nextIntervalText && current.nextIntervalText.duration">
            <b>{{ current.nextIntervalText.duration }}</b>
            <small style="margin: 0 5px; font-size: 12px;">UP NEXT</small>
            <b>{{ current.nextIntervalText.target }}W</b>
          </template>
          <template v-else-if="typeof current.nextIntervalText === 'string'">
            Next {{ current.nextIntervalText }}
          </template>
          <template v-else>
            THE LAST SEGMENT
          </template>
        </span>
      </div>

      <div class="block-target block is-large">
        <div class="title">TARGET
          <template v-if="currentZone">- <strong :class="currentZone.class">Z{{ currentZone.number }}</strong></template>
        </div>
        <div class="center-value">{{ current.targetWatts }}</div>
      </div>
      <div class="block-watts block is-large">
        <div class="title">WATTS</div>
        <div class="center-value">{{ current.watts }}</div>
      </div>

      <div class="t-calories block-bottom-row block is-small">
        <div class="title">STRESS</div>
        <div class="center-value">{{ total.stress }}</div>
      </div>
      <div class="i-calories block-bottom-row block is-small">
        <div class="title">CALORIES</div>
        <div class="center-value">{{ total.calories }}</div>
      </div>

      <div class="t-watts block-bottom-row block is-small">
        <div class="title">TOTAL AVG</div>
        <div class="center-value">{{ current.averageWatts }}</div>
      </div>
      <div class="i-watts block-bottom-row block is-small">
        <div class="title">INTERVAL AVG</div>
        <div class="center-value">{{ current.averageSegmentWatts }}</div>
      </div>
    </div>

    <div class="timing-section">
      <div class="block-interval block is-medium">
        <span class="title"><ArrowItem :style="style(current.time.isIntervalUp)" />INTERVAL</span>
        <p class="center-value">{{ convertTime(current.time.interval) }}</p>
      </div>
      <div class="block-total block is-medium">
        <span class="title"><ArrowItem :style="style(current.time.isTotalUp)" />TOTAL</span>
        <p class="center-value">{{ convertTime(current.time.total) }}</p>
      </div>

      <div class="block-rpm block is-large">
        <span class="title">{{ rpmTitle }}</span>
        <p class="center-value" :class="{'text-red': isNotMatchRPMTarget }">{{ current.rpm }}</p>
        <span v-show="current.rpmTarget.min > 0" class="bottom-value left-bottom-value">
          {{ current.rpmTarget.min }}
        </span>
        <span v-show="current.rpmTarget.max > 0" class="bottom-value right-bottom-value">
          {{ current.rpmTarget.max }}
        </span>
      </div>
      <div class="block-bpm block is-large">
        <span class="title">BPM</span>
        <p class="center-value">{{ current.bpm }}</p>
      </div>

      <div class="block-bottom-row block is-small">
        <div class="title">TOTAL AVG</div>
        <div class="center-value">{{ total.averageRpm }}</div>
      </div>
      <div class="block-bottom-row block is-small">
        <div class="title">INTERVAL AVG</div>
        <div class="center-value">{{ intervalAverageRpm }}</div>
      </div>

      <div class="block-bottom-row block is-small">
        <div class="title">TOTAL AVG</div>
        <div class="center-value">{{ current.averageBpm }}</div>
      </div>
      <div class="block-bottom-row block is-small">
        <div class="title">INTERVAL AVG</div>
        <div class="center-value">{{ intervalAverageBpm }}</div>
      </div>
    </div>

    <div class="actions-section">
      <div class="block is-small">
        <a v-if="user.hasLoaded && !isLogged" href="#" class="action-icon-link" @click.prevent="signIn">
          <svg-icon icon="sign-in"/>
          <div>Sign in</div>
        </a>
        <template v-if="user.hasLoaded && isLogged">
          <a href="#" class="action-icon-link" :class="{'active': youTubeList}" @click.prevent="changeFrame">
            <svg-icon icon="youtube"/>
          </a>
          <a
              href="#"
              class="action-icon-link"
              :class="{'active': isShowRouteMap}"
              @click.prevent="toggleDisplayMap"
          >
            <svg-icon icon="map-route"/>
          </a>
        </template>
      </div>
      <div class="block is-small speed-distance-block">
        <div class="values">
          <div>{{ speedText }}</div>
          <div>{{ distanceText }}</div>
        </div>
        <svg-icon v-if="false" :icon="sportIcon"/>
        <a
            href="#"
           :class="{'active': isShowBottomRow}"
            class="action-icon-link chevron-link"
            @click.prevent="toggleDisplayBottomRow"
        >
          <svg-icon icon="chevron-down"/>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  import { getTimeLineOfSegment } from '@trainerday/cycling-converter'
  import { SportType } from '@trainerday/ergdb-types'
  import { getActivityTrainingStress } from '@trainerday/cycling-metrics'
  import { wattsToFtp } from '@trainerday/cycling-converter/dist/converter/utils/index'
  import CanvasChart from '@/components/CanvasChart'
  import ArrowItem from '@/components/ArrowItem'
  import { mapActions, mapState, mapMutations } from 'vuex'

  export default {
    name: 'BottomBar',
    components: {
      ArrowItem,
      CanvasChart
    },
    data() {
      return {
        segmentComment: {
          text: null,
          intervalTimeoutId: null
        },
        isShowBottomRow: false
      }
    },
    created() {
      const isShowBottomRow = localStorage.getItem('isShowBottomRow')
      if (isShowBottomRow) {
        this.isShowBottomRow = JSON.parse(localStorage.getItem('isShowBottomRow'))
      }
    },
    watch: {
      'current.segmentText'(val) {
        const text = document.querySelector('#segment-comment')
        if (val) {
          if (val) {
            clearTimeout(this.segmentComment.intervalTimeoutId)
            clearInterval(this.segmentComment.intervalTimeoutId)
          }
          this.segmentComment.text = val

          this.$nextTick(() => {
            const block = document.querySelector('.segment-comment-block')
            if (text.offsetWidth > block.offsetWidth) {
              text.setAttribute('style', `animation: comment-ticker-swap ${val.length * 200}ms linear infinite;left:100%`)
              this.segmentComment.intervalTimeoutId = setInterval(() => {
                const text = document.querySelector('#segment-comment')
                if (Math.abs(text.offsetLeft) > Math.abs(text.offsetWidth)) {
                  this.segmentComment.text = null
                  text.setAttribute('style', '')
                  clearInterval(this.segmentComment.intervalTimeoutId)
                  this.segmentComment.intervalTimeoutId = null
                }
              }, 500)
            } else {
              this.segmentComment.intervalTimeoutId = setTimeout(() => {
                this.segmentComment.text = null
                text.setAttribute('style', '')
                clearTimeout(this.segmentComment.intervalTimeoutId)
                this.segmentComment.intervalTimeoutId = null
              }, val.length * 200)
            }
          })
        } else {
          this.segmentComment.text = null
          text.setAttribute('style', '')
        }
      }
    },
    computed: {
      ...mapState(['history', 'current', 'user']),

      chart() {
        return this.user.chart
      },

      userId() {
        return this.user.userId
      },

      speedText() {
        const { speed, params: { units } } = this.current

        if (units === 'metric') {
          return `${Number(Number(speed).toFixed(1))} KPH`
        }
        // One KPH in meters = 1609.34
        return `${Number(Number(speed * 0.621371).toFixed(1))} MPH`
      },

      distanceText() {
        const { distance, params: { units } } = this.current

        if (units === 'metric') {
          if (distance >= 1000) {
            return `${Number(Number(distance / 1000).toFixed(1))} KM`
          }
          return `${Math.round(distance)} M`
        }

        // One mile in meters = 1609.34
        const inMiles = Number(distance / 1609.34).toFixed(1)
        return `${Number(inMiles)} MIL`
      },

      sportIcon() {
        if (this.current.sportType === SportType.SWIM) {
          return 'swimming'
        }
        if (this.current.sportType === SportType.ROWING) {
          return 'rowing'
        }
        return 'bike'
      },

      youTubeList() {
        return this.user.youTubeList
      },

      isShowRouteMap() {
        return this.user.showRouteMap
      },

      chartInBottomBar() {
        return this.user.chartInBottomBar
      },
      isLogged() {
        return this.user.isLogged
      },
      rpmTitle() {
        return this.sportType === 'rowing' ? 'spm'.toUpperCase() : 'rpm'.toUpperCase()
      },

      intervalAverageRpm() {
        if (this.history.rpm.length) {
          return this.getAverageValueOfSegment(this.current.currentSegmentIndex, 'rpm')
        }
        return 0
      },

      intervalAverageBpm() {
        if (this.history.bpm.length) {
          return this.getAverageValueOfSegment(this.current.currentSegmentIndex, 'bpm')
        }
        return 0
      },

      total() {
        const averageRpm = this.history.rpm.length ? Math.round(this.history.rpm.reduce((acc, cur) => acc + cur) / this.history.rpm.length) : 0
        const calories = this.calculateCalories(this.current.averageWatts, this.current.currentSecond / 3600)
        const stress = getActivityTrainingStress(this.history.watts, this.current.ftp) || 0
        return {
          averageRpm,
          calories,
          stress
        }
      },

      isNotMatchRPMTarget() {
        const { rpmTarget: { min, max }, rpm } = this.current
        if (!min && !max) {
          return false
        }
        return !(rpm >= min && rpm <= max)
      },

      currentZone() {
        const { targetWatts, ftp } = this.current
        if (!targetWatts) {
          return null
        }
        const target = wattsToFtp(targetWatts, ftp)
        if (target <= 55) {
          return { number: 1, class: 'zone-1' }
        } else if (target <= 75) {
          return { number: 2, class: 'zone-2' }
        } else if (target <= 90) {
          return { number: 3, class: 'zone-3' }
        } else if (target <= 105) {
          return { number: 4, class: 'zone-4' }
        } else if (target <= 120) {
          return { number: 5, class: 'zone-5' }
        } else {
          return { number: 6, class: 'zone-6' }
        }
      }
    },
    methods: {
      ...mapActions(['showYoutubeFrame']),
      ...mapMutations(['toggleDisplayRouteMap']),

      toggleDisplayMap() {
        if (this.$screenHeight <= 475) {
          return
        }
        const { showRouteMap } = this.user
        const { routeMapBlock: { routeBlockSettings: { isExpanded } } } = this.$parent.$refs
        if (showRouteMap && isExpanded) {
          this.$parent.$refs.routeMapBlock.expandMapToggle()
        }
        this.toggleDisplayRouteMap()
      },

      toggleDisplayBottomRow() {
        this.isShowBottomRow = !this.isShowBottomRow
        localStorage.setItem('isShowBottomRow', this.isShowBottomRow)
      },

      getAverageValueOfSegment(segmentIndex, historyField) {
        if (!this.history.segments || !this.history.segments.length) {
          return 0
        }

        const segmentTimeLine = getTimeLineOfSegment(segmentIndex, this.history.segments)
        const valuesByRange = this.history[historyField].slice(segmentTimeLine[0], segmentTimeLine[1])

        if (valuesByRange.length === 0) {
          return 0
        }
        const average = valuesByRange.reduce((acc, cur) => acc + cur) / valuesByRange.length
        return Math.round(average)
      },

      signIn() {
        window.location.href = 'https://trainerday.com/login'
      },

      changeFrame() {
        if (this.$screenHeight <= 475) {
          return
        }
        this.$store.dispatch('showYoutubeFrame')
      },

      style(isUp) {
        let rotate = 0.5
        if (!isUp) {
          rotate = 0
        }
        return { transform: 'rotate(' + rotate + 'turn)' }
      },

      calculateCalories(watts, hours) {
        const val = watts * hours * 3.6
        return (val < 1 ? Number(val.toFixed(2)) : Math.round(val)) || 0
      },

      convertTime(secs) {
        const secNum = parseInt(secs, 10)
        const hours = Math.floor(secNum / 3600)
        const minutes = Math.floor(secNum / 60) % 60
        const seconds = secNum % 60

        return [hours, minutes, seconds]
          .map((v) => (v < 10 ? '0' + v : v))
          .filter((v, i) => v !== '00' || i > 0)
          .join(':')
      }
    }
  }
</script>

<style lang="scss">
@keyframes comment-ticker-swap {
  0% {
    left: 100%;
  }

  100% {
    left: -300%;
  }
}
.bottom-bar {
  font-family: "Poppins", sans-serif;
  width: 100%;
  display: flex;
  justify-content: right;
  flex-direction: row;
  color: white;
  margin: 0 8px 8px -8px;
  height: 100%;
  background-color: #000000;
  position: inherit;
  .target-section {
    display: grid;
    grid-template-areas: "com com com com" "target target watts watts" ". . . .";
    grid-template-rows: 30% 40% 22%;
    transition: grid-template-rows 0.5s;
    width: 47%;
    grid-gap: 8px;
    position: relative;
    margin: 8px 4px 8px 24px;
    .block-target {
      grid-area: target;
      .title strong {
        margin-left: 5px;
        font-size: 12px;
        color: #eee;
        width: 25px;
        border-radius: 50%;
        &.zone-1 {
          background-color: #4cdd8a80;
        }
        &.zone-2 {
          background-color: #62a7e880;
        }
        &.zone-3 {
          background-color: #8857e480;
        }
        &.zone-4 {
          background-color: #ffc56280;
        }
        &.zone-5 {
          background-color: #fe595973;
        }
        &.zone-6 {
          background-color: #000000;
        }
      }
    }
    .block-watts {
      grid-area: watts;
    }
    .segment-comment-block {
      grid-area: com;
      justify-content: flex-end;
      overflow: hidden;
      max-width: 100%;
      .comment {
        font-size: 20px;
        position: absolute;
        top: 0;
        white-space: nowrap;
      }
      .next-int {
        align-self: flex-end;
        color: #7e8192;
        font-size: 16px;
        transform: scale(1.2);
        position: absolute;
        //right: calc(50% - 90px);
        left: 0;
        width: 100%;
        bottom: 25%;
        transition: all 1s;
        &.blinking {
          animation: blinker 2s linear infinite;
        }
        &.reduced {
          bottom: 0;
          transform: scale(1);
        }

        @keyframes blinker {
          50% {
            opacity: 0;
          }
        }
      }
    }
  }

  .timing-section {
    display: grid;
    gap: 8px;
    width: 47%;
    grid-template-areas: "int int total total" "rpm rpm bpm bpm" ". . . .";
    grid-template-rows: 30% 40% 22%;
    transition: grid-template-rows 0.5s;
    margin: 8px 4px;
    .block-interval {
      grid-area: int;
    }
    .block-total {
      grid-area: total;
    }
    .block-rpm {
      grid-area: rpm;
    }
    .block-bpm {
      grid-area: bpm;
    }
  }

  .actions-section {
    display: grid;
    gap: 8px;
    width: 100px;
    grid-template-rows: 40% 56%;
    box-sizing: border-box;
    margin: 8px 8px 8px 4px;
    .action-icon-link {
      color: #ffffff;
      text-decoration: none;
      &:hover {
        color: #4ea1feb3;
        .icon {
          color: #4ea1feb3;
        }
      }
      &.active .icon {
        color: #4ea1fe;
      }
    }
    .disabled-link {
      opacity: 0.4;
      pointer-events: none;
    }
  }

  .chart {
    width: 35%;
    display: flex;
    margin-right: -16px;
    margin-left: 8px;
  }

  .block {
    background: #20232f;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    .title {
      color: #83858b;
      font-weight: 500;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      flex-wrap: nowrap;
      padding: 0;
    }
    .center-value {
      font-size: 42px;
      color: #eaeaea;
      margin: 0;
      padding: 0;
    }
    .bottom-value {
      position: absolute;
      font-size: 18px;
      padding: 5px 15px;
      bottom: 0;
    }
    .right-bottom-value {
      right: 0;
    }
    .left-bottom-value {
      left: 0;
    }
    &.is-small {
      .title {
        font-size: 10px;
      }
      .center-value {
        font-size: 24px;
        line-height: 24px;
        color: #7e8192;
      }
    }
    &.is-medium .center-value {
      font-size: 28px;
      line-height: 28px;
    }
    &.is-large .center-value {
      font-size: 50px;
      line-height: 50px;
    }
    &.speed-distance-block {
      justify-content: center;
      .values {
        margin-bottom: 10px;
      }
    }
  }

  .chevron-link {
    position: absolute;
    bottom: 5%;
    .icon {
      transition: transform 0.3s;
    }
    .icon {
      transform: rotate(180deg);
    }
    &.active {
      .icon {
        transform: rotate(0);
      }
    }
  }

  .text-red {
    color: #e7140f !important;
  }
}

.hidden-bottom-row {
  .target-section,
  .timing-section {
    grid-template-rows: 30% 66% 0;
  }
}
@media (min-width: 1920px) {
  .bottom-bar .block.is-large {
    .title {
      font-size: 28px;
    }
    .center-value {
      font-size: 70px;
      line-height: 70px;
    }
  }

  .bottom-bar .block.is-medium {
    .title {
      font-size: 28px;
    }
    .center-value {
      font-size: 38px;
      line-height: 38px;
    }
  }

  .bottom-bar .block.is-small {
    .title {
      font-size: 14px;
    }
    .center-value {
      font-size: 30px;
      line-height: 30px;
    }
  }

  .bottom-bar .target-section {
    .segment-comment-block .comment {
      font-size: 36px;
    }
    .segment-comment-block .next-int {
      font-size: 24px;
    }
  }

  .bottom-bar .speed-distance-block {
    font-size: 20px;
  }
}
</style>
