<template>
  <div
      class="route-map-block"
      :class="{
        'is-opened': isShowRouteMap,
        'is-center': routeBlockSettings.position === 'center',
        'is-left': routeBlockSettings.position === 'left',
        'is-expanded': routeBlockSettings.isExpanded
      }"
  >
    <div class="route-map-block-container">
      <div v-if="routeBlockSettings.screen === 'upload'" class="upload-screen">
        <template v-if="statusActivity === 'ready'">
          <a
              href="#"
              class="white-link upload-link"
              :class="{'is-dragging': isDragging}"
              @click.prevent="attachRouteFile"
              @drop.prevent="uploadDropFileHandler"
              @dragleave.prevent="isDragging = false"
              @dragover.prevent="isDragging = true"
              @dragenter.prevent="isDragging = true"
          >
            <span v-if="isUploading">Loading...</span>
            <template v-else>
              <span v-if="isDragging">Drag file here</span>
              <template v-else>
                <span>Choose a file or drag it here</span>
                <span>Upload GPX, TCX, or FIT file</span>
              </template>
            </template>
          </a>
          <input ref="routeFileInput" type="file" accept=".gpx,.fit,.tcx" @change="processRouteFile"/>
        </template>
        <span v-else class="not-upload-text">
          You cannot upload a route if a workout has already started
        </span>
      </div>

      <template v-if="routeBlockSettings.screen === 'map'">
        <RouteMap
            :mapHeight="routeBlockSettings.isExpanded ? routeBlockSettings.expandedMapHeight : undefined"
            :coordinates="userRoute.coordinates"
            :traveledDistance="current.distance"
            :currentSecond="current.currentSecond"
            :status="current.statusActivity"
            :startDateLocal="current.startDateLocal"
            :lastRideFinishCoordinates="userRoute.lastRideFinishCoordinates"
            @saveRoute="updateLastRideData"
            @expandMap="expandMapToggle"
        />
        <div class="bottom-controls">
          <a href="#" class="white-link btn-link" @click.prevent="changeBlockPosition('left')">{{ '<<' }}</a>
          <a href="#" class="white-link btn-link" @click.prevent="deleteRoute">
            Delete Route
          </a>
          <a href="#" class="white-link btn-link" @click.prevent="changeBlockPosition('right')">
            {{ '>>' }}
          </a>
        </div>
      </template>

    </div>
  </div>
</template>

<script>
  import { mapMutations, mapState } from 'vuex'
  import * as Sentry from '@sentry/browser'
  import { gpxToJson, fitToJson, tcxToJson } from '@trainerday/cycling-converter'
  import RouteMap from '@/components/RouteMap'

  export default {
    name: 'RouteMapBlock',
    components: {
      RouteMap
    },
    data() {
      return {
        isDragging: false,
        isUploading: false,
        isNotSaveCoords: false,
        routeBlockSettings: {
          position: 'right',
          screen: 'upload',
          isExpanded: false,
          expandedMapHeight: null
        }
      }
    },
    computed: {
      ...mapState(['current', 'user', 'userRoute']),

      statusActivity() {
        return this.current.statusActivity
      },

      isShowRouteMap() {
        return this.user.showRouteMap
      }
    },
    watch: {
      statusActivity(status, prevStatus) {
        if (status === 'playing' && prevStatus === 'ready') {
          if (this.$confirmModal.isShow) {
            this.$confirmModal.confirm()
          }
        }

        if (status === 'saving') {
          this.isNotSaveCoords = false
          if (this.routeBlockSettings.screen === 'upload' && this.userRoute.isLoaded) {
            this.routeBlockSettings.screen = 'map'
          }
        }
      },

      'userRoute._id'(val) {
        if (val) {
          if (this.statusActivity === 'ready' && this.user.isLogged) {
            if (!this.isShowRouteMap) {
              this.showCurrentRouteNotification()
            }
          }
        }
      },

      'current.currentSecond'(second, prevSecond) {
        if (second === Math.round((this.current.workout.durationMinutes * 60) / 2)) {
          this.routeBlockSettings.position = 'left'
        }

        // if the user refreshed the page during training, then we do not save incomplete coordinates
        if ((second - prevSecond) > 3) {
          this.isNotSaveCoords = true
          this.routeBlockSettings.screen = 'upload'
        }
      }
    },
    methods: {
      ...mapMutations(['toggleDisplayRouteMap']),

      uploadDropFileHandler(e) {
        this.isDragging = false
        const { files } = e.dataTransfer
        this.processRouteFile(files)
      },

      expandMapToggle() {
        if (!this.routeBlockSettings.isExpanded) {
          const bar = window.document.querySelector('#bottom-bar')
          const routeMapBlockControls = this.$el.querySelector('.bottom-controls')
          const { height: barHeight } = bar.getBoundingClientRect()
          const { height: controlsHeight } = routeMapBlockControls.getBoundingClientRect()
          this.routeBlockSettings.expandedMapHeight = `${window.innerHeight - barHeight - controlsHeight}px`
        } else {
          this.routeBlockSettings.expandedMapHeight = null
        }
        this.routeBlockSettings.isExpanded = !this.routeBlockSettings.isExpanded
      },

      showCurrentRouteNotification() {
        this.$confirmModal.open({
          message: 'Do you want to continue the loaded route of the last workout?',
          cancelButton: 'Delete route',
          confirmButton: 'Continue'
        }).then((confirm) => {
          if (confirm) {
            this.routeBlockSettings.screen = 'map'
            this.toggleDisplayRouteMap()
          } else {
            this.$store.dispatch('deleteRoute')
            this.routeBlockSettings.screen = 'upload'
          }
        })
      },

      async updateLastRideData({ coordinates, traveledDistance, startDateLocal }) {
        if (this.userRoute.isLoaded && !this.isNotSaveCoords) {
          // One element of coordinates is equal to 1 second
          // If there are more seconds than coordinates by 60, then we cannot save this route
          // This may be due to poor internet connection.
          const secondsDifference = Math.abs(coordinates.length - this.current.currentSecond)

          if (secondsDifference > 60) {
            Sentry.configureScope(scope => {
              scope.setUser(this.user.userId)
              scope.setContext('', {
                secondsDifference,
                seconds: this.current.currentSecond
              })
              Sentry.captureException(new Error('Incorrect coordinates of the traveled path'))
            })
            this.$store.commit('clearHistory')
            this.$store.commit('clearCurrent')
            this.$store.commit('clearRouteLastRide')
            this.$confirmModal.open({
              message: 'Your ride is finished! We cannot save your passed coordinates. Please report it on the forum',
              cancelButton: 'Get it',
              confirmButton: null
            })
            return
          }

          // add coordinates at the end for 60 seconds so as not to break tcx
          const payload = {
            _id: this.userRoute._id,
            lastRide: {
              startDateLocal,
              totalDistanceMeters: traveledDistance,
              coordinates: [...coordinates, ...Array.from(new Array(60)).map(() => coordinates[coordinates.length - 1])]
            }
          }
          await this.$store.dispatch('updateRoute', payload)
          this.$store.commit('clearHistory')
          this.$store.commit('clearCurrent')
          this.$confirmModal.open({
            message: 'Your ride is finished! We will save your traveled path to activity!',
            cancelButton: 'Get it',
            confirmButton: null
          })
        }
      },

      async deleteRoute() {
        const confirmed = await this.$confirmModal.open({
          message: 'Are you sure you want to delete the route?',
          cancelButton: 'Cancel'
        })
        if (confirmed) {
          await this.$store.dispatch('deleteRoute')
          this.routeBlockSettings.isExpanded = false
          this.routeBlockSettings.screen = 'upload'
          this.toggleDisplayRouteMap()
        }
      },

      changeBlockPosition(pos) {
        const { position } = this.routeBlockSettings
        let newPos = 'right'

        if (pos === 'left') {
          if (position === 'right') {
            newPos = 'center'
          }
          if (position === 'center') {
            newPos = 'left'
          }
        }

        if (pos === 'right') {
          if (position === 'left') {
            newPos = 'center'
          }
          if (position === 'center') {
            newPos = 'right'
          }
        }

        this.routeBlockSettings.position = newPos
      },

      attachRouteFile() {
        this.$refs.routeFileInput.click()
      },

      async getFileContentString(file) {
        return new Promise((resolve) => {
          const reader = new FileReader()
          reader.readAsArrayBuffer(file)
          reader.onload = (e) => {
            const { result } = e.target
            const data = new Uint8Array(result)
            const content = new TextDecoder('utf-8').decode(data)
            resolve(content)
          }
        })
      },

      async processRouteFile(dragFiles = []) {
        this.isUploading = true
        let files = []
        let coordinates = []

        if (dragFiles.length) {
          files = dragFiles
        } else {
          const input = this.$refs.routeFileInput
          files = input ? input.files : []
        }

        if (files.length === 0) {
          this.isUploading = false
          return
        }
        const file = files[0]
        const content = await this.getFileContentString(file)

        const filenameArray = file.name.split('.')
        const fileType = filenameArray.pop().toLocaleLowerCase()

        try {
          if (fileType === 'gpx') {
            const data = gpxToJson(content)
            const { trackPoints } = data
            coordinates = trackPoints.map(([, , lat, lng]) => ({ lat, lng }))
          }

          if (fileType === 'tcx') {
            const trackPoints = tcxToJson(content)
            coordinates = trackPoints
              .filter(([, , , lat, lng]) => lat && lng)
              .map(([, , , lat, lng]) => ({ lat, lng }))
          }

          if (fileType === 'fit') {
            const fileBuffer = await file.arrayBuffer()
            const data = fitToJson(fileBuffer)
            const { trackPoints } = data
            coordinates = trackPoints.map(([, , lat, lng]) => ({ lat, lng }))
          }

          if (!coordinates.length) {
            this.$confirmModal.open({
              message: 'File has no coordinates',
              cancelButton: '',
              confirmButton: ''
            })
            setTimeout(() => {
              this.$confirmModal.cancel()
              this.isUploading = false
            }, 2000)
            return
          }

          await this.$store.dispatch('saveRoute', { coordinates })
          this.routeBlockSettings.screen = 'map'
          this.isUploading = false
        } catch (e) {
          this.isUploading = false
          this.$confirmModal.open({
            message: 'File read error',
            cancelButton: '',
            confirmButton: ''
          })
          setTimeout(() => {
            this.$confirmModal.cancel()
          }, 2000)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
.route-map-block {
  position: absolute;
  right: 20px;
  top: 10px;
  transform: translate(0, 0);
  transition: transform, right .5s, .5s;
  background-color: #000000;
  border-radius: 4px;
  .white-link {
    color: #ffffff;
    text-decoration: none;
  }
  &.is-opened {
    transform: translate(0, -210px);
  }
  &.is-left {
    right: calc(100vw - 350px);
  }
  &.is-center {
    right: calc(50vw - 150px);
  }
  &.is-expanded {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: unset;
    transform: unset;
    .route-map-block-container {
      width: 100%;
      height: unset;
    }
  }
  &-container {
    width: 300px;
    height: 200px;
  }
  input {
    display: none;
  }
  .btn-link {
    display: inline-block;
    padding: 0 10px;
  }
  .upload-screen {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #20232f;
    border-radius: 4px;
    .upload-link {
      width: 85%;
      height: 60%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%2383858BFF' stroke-width='2' stroke-dasharray='9' stroke-dashoffset='6' stroke-linecap='square'/%3e%3c/svg%3e");
      border-radius: 4px;
      margin-top: 30px;
      margin-bottom: 30px;
      color: #ffffff;
      span:nth-child(2) {
        color: #4ea1fe;
        font-size: 12px;
      }
      &.is-dragging {
        animation: blinker 1s linear infinite;
        @keyframes blinker {
          50% {
            opacity: 0;
          }
        }
      }
    }
  }
  .bottom-controls {
    display: flex;
    justify-content: space-between;
  }
  .not-upload-text {
    color: #ffffff;
    padding: 0 50px;
  }
}
</style>
